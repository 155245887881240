import React from 'react';
import { Router, Route } from 'react-router-dom';
import history from './history';
import './App.css';

// pages
import LoginPage from './pages/Login';
import Dashboard from './pages/Dashboard';
import CustomerDetail from './pages/customers/CustomerDetail';
import EditCustomer from './pages/customers/EditCustomer';

class App extends React.Component {
  render() {
    return (
      <Router history={history}>
        <Route exact path='/' component={LoginPage} />
        <Route path='/dashboard' component={Dashboard} />
        <Route exact path='/customer/:id/edit' component={EditCustomer} />
        <Route exact path='/customer/:id' component={CustomerDetail} />
      </Router>
    )
  }
}

export default App;
