import { FETCH_CUSTOMERS_REQUEST, FETCH_CUSTOMERS_SUCCESS, FETCH_CUSTOMERS_FAILURE } from '../constants/actionTypes';

const initialState = {
  customers: [],
  loading: false,
};

export function customers(state = initialState, action) {
  switch(action.type) {
    case FETCH_CUSTOMERS_REQUEST:
      return {
        ...state,
        loading: action.payload
      }
    case FETCH_CUSTOMERS_SUCCESS:
      return {
        ...state,
        customers: action.payload
      }
    case FETCH_CUSTOMERS_FAILURE:
      return {
        ...state,
        loading: false,
      }
    default:
      return state;
  }
}
