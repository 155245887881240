import { apiEndpoint } from '../api/';
import axios from 'axios';

function handleResponse(response) {
  if (!response.success) {
    return response.json()
      .then((err) => Promise.reject(err));
  }
  return response.data;
}

async function fetchCustomers(payload) {
  return axios({
    url: `${apiEndpoint}/api/customer`,
    method: 'GET',
    data: payload
  }).then(data => handleResponse(data.data))
}

export const customerServices = {
  fetchCustomers
}
