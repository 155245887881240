import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Drawer from "../components/Drawer";
import { Table, Card, Tag, Input, Spin, Button, Divider } from "antd";
import { Link } from 'react-router-dom';
import Excel from '../components/Excel';
import { customerActions } from '../actions/customerAction';
import moment from 'moment';
const { Search } = Input;


const Dashboard = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.customers);
  const loading = useSelector(state => state.customers.loading);

  useEffect(() => {
    dispatch(customerActions.fetchAllCustomers())
  }, []);

  const userColumns = [
    {
      title: "No",
      dataIndex: "id",
      key: "no",
      render: (text, record, index) => <span>{index + 1}</span>
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name"
    },
    {
      title: 'Age',
      dataIndex: 'age',
      key: 'age',
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone"
    },
    {
      title: "Referral",
      dataIndex: "referral",
      key: "referral"
    },
    {
      title: "NRC NO",
      dataIndex: "nrc_no",
      key: "nrc_no"
    },
    {
      title: 'numbers of TV',
      dataIndex: 'no_of_tv',
      key: 'no_of_tv',
    },
    {
      title: 'Region',
      dataIndex: 'region',
      key: 'region',
    },
    {
      title: 'TSP Address',
      dataIndex: 'tsp_address',
      key: 'tsp_address',
    },
    {
      title: "Latitude",
      dataIndex: "lat",
      key: "latitude"
    },
    {
      title: "Longitude",
      dataIndex: "lon",
      key: "longitude"
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      width: 150,
    },
    {
      title: 'Registered Date',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text, record) => {
        return <span>{moment(record.created_at).format("MMMM-DD, YYYY")}</span>
      },
    },
    {
      title: 'Actions',
      render: (text, record) => {
        return (
          <span>
            <Link to={`/customer/${record.id}`}>
              <Button>View</Button>
            </Link>
            <Divider type="vertical"/>
            <Button type="primary">Active</Button>
          </span>

        )
      }
    }
  ];

  const excelColumns = [
    {
      title: "No",
      dataIndex: "id",
      key: "no",
      render: (text, record, index) => <span>{index + 1}</span>
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name"
    },
    {
      title: 'Age',
      dataIndex: 'age',
      key: 'age',
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone"
    },
    {
      title: "Referral",
      dataIndex: "referral",
      key: "referral"
    },
    {
      title: "NRC NO",
      dataIndex: "nrc_no",
      key: "nrc_no"
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      key: 'gender',
    },
    {
      title: 'numbers of TV',
      dataIndex: 'no_of_tv',
      key: 'no_of_tv',
    },
    {
      title: 'Region',
      dataIndex: 'region',
      key: 'region',
    },
    {
      title: 'TSP Address',
      dataIndex: 'tsp_address',
      key: 'tsp_address',
    },
    {
      title: "Latitude",
      dataIndex: "lat",
      key: "latitude"
    },
    {
      title: "Longitude",
      dataIndex: "lon",
      key: "longitude"
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      width: 150,
    },
    {
      title: 'Registered Date',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text, record) => {
        return <span>{moment(record.created_at).format("MMMM-DD, YYYY")}</span>
      },
    },
  ];

  const excelData = state.customers.map((item, index) => {
    const obj = Object.assign({}, item);
    obj['gender'] = item.gender === 1 ? 'Male' : 'Female';
    return obj;
  });
  
  return (
    <Drawer>
    <Spin spinning={loading} tip="loading...">
    <Card
      title="User List"
      extra={
        <div style={{ display: "flex", flexDirection: "row" }}>
          <h6 className="mr-2 mt-2">
            Total:{" "}
            <span style={{ fontWeight: "bold" }}>
              {state.customers.length}
            </span>
          </h6>
          <Search
            placeholder="Search"
            // onSearch={this._onSearchText}
            // onChange={this._onSearchTextChange}
            style={{ width: 200 }}
          />
          <Excel data={excelData} columns={excelColumns} rowKey="id" />
        </div>
      }
    >
      <Table
        columns={userColumns}
        dataSource={state.customers}
        rowKey="id"
        scroll={{ x: "auto" }}
      />
    </Card>

    </Spin>
    </Drawer>
  );
}

export default Dashboard;
