import { FETCH_CUSTOMERS_REQUEST, FETCH_CUSTOMERS_SUCCESS, FETCH_CUSTOMERS_FAILURE } from '../constants/actionTypes';
import { customerServices } from '../services/customerService';

function fetchAllCustomers(data) {
  function request(payload) {
    return { type: FETCH_CUSTOMERS_REQUEST, payload }
  }
  function success(payload) {
    return { type: FETCH_CUSTOMERS_SUCCESS, payload }
  }
  function failure(payload) {
    return { type: FETCH_CUSTOMERS_FAILURE, payload }
  }
  return dispatch => {
    dispatch(request(true))
    customerServices.fetchCustomers(data)
    .then(payload => {
      dispatch(success(payload))
      dispatch(request(false))
    })
    .catch(err => dispatch(failure(err.message)))
  }
}

export const customerActions = {
  fetchAllCustomers
}
