import React, { useEffect, useState } from 'react';
import Drawer from "../../components/Drawer";
import { Card, Tag, Button } from 'antd';
import { Link } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import { apiEndpoint } from '../../api/';

function Customer(props) {
  const [resources, setResources] = useState([])
  const id = props.match.params.id;
  useEffect(() => {
    axios.get(`${apiEndpoint}/api/customer/${id}`).then(res => {
      setResources(res.data);
    });
  }, [id]);
  return (
    <Drawer>
      <Card title="User Detail"
      extra={
        <span>
          <Link to={`/customer/${props.match.params.id}/edit`}>
            <Button>Edit</Button>
          </Link>
        </span>
      }
      >
        <div className="row mb-3">
          <div className="col-md-2">Name:</div>
          <div className="col-md-4 text-left"> {resources.name}</div>
        </div>
        <div className="row mb-3">
          <div className="col-md-2">Age:</div>
          <div className="col-md-4 text-left"> {resources.age}</div>
        </div>
        <div className="row mb-3">
          <div className="col-md-2">Phone Number:</div>
          <div className="col-md-4 text-left"> {resources.phone}</div>
        </div>
        <div className="row mb-3">
          <div className="col-md-2">Referral Number:</div>
          <div className="col-md-4 text-left"> {resources.referral}</div>
        </div>
        <div className="row mb-3">
          <div className="col-md-2">NRC Number</div>
          <div className="col-md-4 text-left"> {resources.nrc_no}</div>
        </div>
        <div className="row mb-3">
          <div className="col-md-2">Gender</div>
          <div className="col-md-4 text-left">
            {
              resources.gender === 1
              ? 'Male'
              : 'Female'
            }
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-2">Region</div>
          <div className="col-md-4 text-left"> {resources.region}</div>
        </div>
        <div className="row mb-3">
          <div className="col-md-2">Address:</div>
          <div className="col-md-4 text-left"> {resources.address}</div>
        </div>
        <div className="row mb-3">
          <div className="col-md-2">Numbers of TV:</div>
          <div className="col-md-4 text-left"> {resources.no_of_tv}</div>
        </div>
        <div className="row mb-3">
          <div className="col-md-2">TSP Address:</div>
          <div className="col-md-4 text-left"> {resources.tsp_address}</div>
        </div>
        <div className="row mb-3">
          <div className="col-md-2">Latitude:</div>
          <div className="col-md-4 text-left"> {resources.lat}</div>
        </div>
        <div className="row mb-3">
          <div className="col-md-2">Longitude:</div>
          <div className="col-md-4 text-left"> {resources.lon}</div>
        </div>
        <div className="row mb-3">
          <div className="col-md-2">Status:</div>
          <div className="col-md-4 text-left"> {resources.status === 1 ? <Tag color="green">Pending</Tag> : <Tag color="green">Accepted</Tag>}</div>
        </div>
        <div className="row mb-3">
          <div className="col-md-2">Registered date:</div>
          <div className="col-md-4 text-left"> {moment(resources.created_at).format("MMMM-DD, YYYY")}</div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <img
                src={`http://tumertirc.hostmyanmar.org/uploads/${resources.front_image}`}
                alt={resources.name}
                style={{ width: '100%' }}
              />
            </div>
            <div className="col-md-4">
              <img
                src={`http://tumertirc.hostmyanmar.org/uploads/${resources.back_image}`}
                alt={resources.name}
                style={{ width: '100%' }}
              />
            </div>
          </div>
        </div>

      </Card>

    </Drawer>
  )
}


export default Customer;
