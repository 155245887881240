import React from "react";
import ReactExport from "react-export-excel";
import moment from 'moment';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class Excel extends React.Component {
  render() {
    const  currentDate = moment().format("MMMM-DD-YYYY");
    return (
      <ExcelFile filename={currentDate} element={<button className="btn btn-info ml-3">Download Data</button>}>
        <ExcelSheet data={this.props.data} name="Employees">
          {this.props.columns.map((item, index) => {
            return <ExcelColumn label={item.title} value={item.dataIndex} key={index} />
          })}
        </ExcelSheet>
      </ExcelFile>
    );
  }
}
