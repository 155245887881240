import { createStore, applyMiddleware, compose } from 'redux';
import reduxThunk from 'redux-thunk';
import rootReducers from './reducers';

const middleWare = applyMiddleware(reduxThunk);

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// export const store = createStore(rootReducers, composeEnhancers(middleWare));

export const store = createStore(rootReducers, middleWare);
