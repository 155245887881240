import React from "react";
import { Form, Icon, Input, Button } from "antd";
import history from "../history";
import Logo from '../TruV_Logo.png'

class Login extends React.Component {
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
        if (
          values.username === "admin@hostmyanmar.net" &&
          values.password === "internet"
        ) {
          history.push("/dashboard");
        }
      }
    });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="container set_login_box">
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6" style={{ width: '100%' }}>
            <Form
              onSubmit={this.handleSubmit}
              className="login-form"
              style={{
                border: "1px solid #ddd",
                padding: 20,
                borderRadius: 6,
              }}
            >
              <div className="text-center mb-2">
                <img
                  src={Logo}
                  alt="logo"
                  width="30%"
                  className="mb-2"
                />
                <h5>TRU Virtues Research Firm</h5>
              </div>
              <Form.Item>
                {getFieldDecorator("username", {
                  rules: [
                    { required: true, message: "Please input your username!" }
                  ]
                })(
                  <Input
                    prefix={
                      <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    placeholder="Username"
                  />
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator("password", {
                  rules: [
                    { required: true, message: "Please input your Password!" }
                  ]
                })(
                  <Input
                    prefix={
                      <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    type="password"
                    placeholder="Password"
                  />
                )}
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button btn-block"
                >
                  Log in
                </Button>
              </Form.Item>
            </Form>
          </div>
          <div className="col-md-3"></div>
        </div>
      </div>
    );
  }
}

const LoginPage = Form.create({ name: "normal_login" })(Login);

export default LoginPage;
