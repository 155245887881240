import React, { useEffect, useState } from 'react';
import Drawer from "../../components/Drawer";
import { Card, Tag, Input, InputNumber, Radio, Button } from 'antd';
import axios from 'axios';
import moment from 'moment';
import history from '../../history';
import { apiEndpoint } from '../../api/';

const EditCustomer = (props) => {
  const [resources, setResources] = useState([])
  const id = props.match.params.id;
  useEffect(() => {
    axios.get(`${apiEndpoint}/api/customer/${id}`).then(res => {
      setResources(res.data);
    });
  }, [id]);
  // useEffect(() => {
  //   axios.get(`http://192.168.1.11:3333/api/customer/${id}`).then(res => {
  //     setResources(res.data);
  //   });
  // }, [id]);

  const onInputChange = (e) => setResources({
    ...resources,
    [e.target.name]: e.target.value
  });


  const onFormSubmit = e => {
    e.preventDefault();

    axios.put(`${apiEndpoint}/api/customer/${id}`, {
      data: resources
    })
    .then(function (response) {
      console.log(response);
    })
    .catch(function (error) {
      console.log(error);
    });

    history.push(`/customer/${id}`);

  }

  return (
    <Drawer>
      <Card title="Edit User" extra={
        <Button onClick={onFormSubmit}>Submit</Button>
      }>
      <div className="row mb-3">
        <div className="col-md-2">Name:</div>
        <div className="col-md-4 text-left">
          <Input value={resources.name} name="name" onChange={onInputChange} />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-md-2">Age:</div>
        <div className="col-md-4 text-left">
          <InputNumber
            min={1}
            max={99}
            value={resources.age}
            name="age"
            onChange={(value) => setResources({ ...resources, age: value })} />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-md-2">Phone Number:</div>
        <div className="col-md-4 text-left">
          <Input value={resources.phone} name="phone" onChange={onInputChange} />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-md-2">Referral Number:</div>
        <div className="col-md-4 text-left">
          <Input value={resources.referral} name="referral" onChange={onInputChange} />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-md-2">NRC Number</div>
        <div className="col-md-4 text-left">
          <Input value={resources.nrc_no} name="nrc_no" onChange={onInputChange} />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-md-2">Gender</div>
        <div className="col-md-4 text-left">
          <Radio.Group value={resources.gender} name="gender" onChange={onInputChange} >
            <Radio value={1}>Male</Radio>
            <Radio value={2}>Female</Radio>
          </Radio.Group>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-md-2">Region</div>
        <div className="col-md-4 text-left">
          <Input value={resources.region} name="region" onChange={onInputChange} />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-md-2">Address:</div>
        <div className="col-md-4 text-left">
          <Input value={resources.address} name="address" onChange={onInputChange} />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-md-2">Numbers of TV:</div>
        <div className="col-md-4 text-left">
          <Input value={resources.no_of_tv} name="no_of_tv" onChange={onInputChange} />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-md-2">TSP Address:</div>
        <div className="col-md-4 text-left">
          <Input value={resources.tsp_address} name="tsp_address" onChange={onInputChange} />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-md-2">Latitude:</div>
        <div className="col-md-4 text-left">
          <Input value={resources.lat} name="lat" onChange={onInputChange} disabled={true} />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-md-2">Longitude:</div>
        <div className="col-md-4 text-left">
          <Input value={resources.lon} name="lon" onChange={onInputChange} disabled={true} />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-md-2">Status:</div>
        <div className="col-md-4 text-left"> {resources.status === 1 ? <Tag color="green">Pending</Tag> : <Tag color="green">Accepted</Tag>}</div>
      </div>
      <div className="row mb-3">
        <div className="col-md-2">Registered date:</div>
        <div className="col-md-4 text-left"> {moment(resources.created_at).format("MMMM-DD, YYYY")}</div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <img
              src={`http://tumertirc.hostmyanmar.org/uploads/${resources.front_image}`}
              alt={resources.name}
              style={{ width: '100%' }}
            />
          </div>
          <div className="col-md-4">
            <img
              src={`http://tumertirc.hostmyanmar.org/uploads/${resources.back_image}`}
              alt={resources.name}
              style={{ width: '100%' }}
            />
          </div>
        </div>
      </div>
      </Card>
    </Drawer>
  );
}

export default EditCustomer;
