import React from "react";
import { Layout, Menu, Icon, Avatar, Dropdown } from "antd";
import { Link } from "react-router-dom";
const { Header, Content, Sider } = Layout;

const menu = (
  <Menu>
    <Menu.Item>
      <Link to="/">Logout</Link>
    </Menu.Item>
  </Menu>
);

class Drawer extends React.Component {
  state = {
    collapsed: false
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed
    });
  };
  render() {
    return (
      <Layout style={{ height: "100vh" }}>
        <Sider
          className="sider"
          breakpoint="lg"
          collapsedWidth="0"
          trigger={null}
          collapsible
          collapsed={this.state.collapsed}
        >
          <div style={{ padding: 20, textAlign: "center" }}>
            <h3 className="text-white">TRU|Metric</h3>
          </div>
          <Menu theme="light" mode="inline">
            <Menu.Item key="1">
              <Link to='/dashboard'>
                <Icon type="user" />
                <span className="nav-text">Users</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="2">
              <Link to="/">
                <Icon type="user" />
                <span className="nav-text">
                Logout
                </span>
              </Link>
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout>
          <Header
            className="navbar_header"
            style={{
              backgroundColor: "#fff",
              padding: "3px",
              lineHeight: "3.5rem"
            }}
          >
            <div className="row">
              <div className="col-6">
                <Icon
                  className="trigger navbar_icon"
                  type={this.state.collapsed ? "menu-unfold" : "menu-fold"}
                  onClick={this.toggle}
                />
              </div>
              <div className="col-5 text-right" style={{ paddingRight: 10 }}>
                <Dropdown overlay={menu} placement="bottomRight">
                  <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
                </Dropdown>
              </div>
              <div className="col-1"></div>
            </div>
          </Header>
          <Content style={{ margin: "24px 16px 0" }}>
            {this.props.children}
          </Content>
        </Layout>
      </Layout>
    );
  }
}

export default Drawer;
